import { Typography } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { formatAsIntOrNDecimals } from '../../../../../Globals/StringFormatting';

// Define the type for a customer
type Customer = {
    id: number;
    firstName: string;
    lastName: string;
};

// Define the props type
interface CustomerNamePageProps {
    customer: Customer;
    SahNumber: string;
    jobContractTotal: number;
    financingTerm?: number;
}

function CustomerNamePage({ customer, SahNumber, jobContractTotal, financingTerm }: CustomerNamePageProps) {
    return (
        <Navbar className="bg-body-tertiary">
            <Container fluid>
                <Row style={{ width: "100%" }}>
                    <Col style={{ display: "flex", alignItems: "flex-end" }}>
                        <Row style={{ width: "100%" }}>
                            <Col><Typography>{SahNumber}</Typography></Col>
                            <Col><Typography>{customer.firstName} {customer.lastName}</Typography></Col>
                        </Row>
                    </Col>
                    {financingTerm && (
                        <Col><Typography align="center">Financing: {financingTerm} Months</Typography></Col>
                    )}
                    <Col>
                        <Row>
                            <Col className="text-end" md={6}><Typography>Contract total</Typography></Col>
                            <Col className="text-end" md={6}><Typography>{`$${formatAsIntOrNDecimals(jobContractTotal)}`}</Typography></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
}

export default CustomerNamePage;
