import {  Typography} from "@material-ui/core";
import { Route, Switch, useHistory } from "react-router-dom";
import { useState } from "react";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import NavbarPage from "Components/Page";
import {  useGetAllFinishedJobConfigurationIdsQuery, useGetCommisionHeaderDetailsQuery} from "../../../../generated/graphql";
import { buildAppendedId, idUrlMask, twoIdUrlMask } from "../../../../Globals/Hooks";
import { commissionQuote, commissionRatesPath } from "../../../../Globals/PathStrings";
import QuotePage from "./QuotePage";

 function Commission() {

    const history = useHistory();
     
     const { data } = useGetAllFinishedJobConfigurationIdsQuery();  
   

    function changePage(path: string, configId: number) {
        history.push(path + buildAppendedId(configId));
    }

    return (
        <NavbarPage
        title={"Completed Projects"}
        padContent
        >
            <div
                className="flex-column"
                style={{ width: "60rem", margin: "0 auto" }}
            >
                {data?.allFinishedJobConfigurationIds.map((jobConfigId) => {
                    return (
                        <JobRowComm
                            key={`job-row-${jobConfigId}`}
                            jobConfigId={jobConfigId}
                            goToPath={changePage}
                        />
                    );
                })}
            </div>
    </NavbarPage>
    )
}

interface JobRowCommProps {
    jobConfigId: number;
    goToPath: (path: string, jobId: number)=> void;
}
function JobRowComm({ jobConfigId, goToPath }: JobRowCommProps) {
    
    
    const { data} = useGetCommisionHeaderDetailsQuery({
        variables: { jobConfigurationId: jobConfigId },
        skip: !jobConfigId
    });
   
    const [selectedConfig, setSelectedConfig] = useState<number | undefined>(undefined);



    if (data?.jobConfigurationHeader === undefined || data?.jobConfigurationHeader.customer===undefined)
        return <></>

    const { jobConfigurationHeader } = data;

    const customer = jobConfigurationHeader.customer;
    function onPathButton(path: string) {
        console.log(path)
        //goToPath(path, selectedConfig ?? configIds[0].id);
        goToPath(path, jobConfigId);
        //query for getting jobconfigId
    }

    return (
        <div
            className="flex-row fill-width h-5r"
            style={{ alignItems: "center", border: "gray 2px solid", borderRadius: "5px" }}
        >
            <div className="w-15r flex-column">
                <Typography variant="h5">Customer:</Typography>
                <Typography variant="h5">{`${customer.lastName}, ${customer.firstName}`}</Typography>
            </div>
            <SpacedButton
                onClick={() => onPathButton(commissionQuote)}
                variant="contained"
            >
              Sales Commission
            </SpacedButton>
            

        </div>
    )
}

export default function CommissionRates() {
    return (
        <Switch>          

            <Route path={commissionQuote + twoIdUrlMask}>
                <QuotePage />
            </Route>

            <Route path={commissionRatesPath + idUrlMask}>
                <Commission />
            </Route>

        </Switch>
    )
}