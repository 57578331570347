import { Container } from "@material-ui/core";
import SpacedButton from "Components/Forms/Controls/SpacedButton";
import NavbarPage from "Components/Page";
import PermissionRoute from "Components/RequirePermissionRoute";
import { useGetAuthenticatedWorkerPermissionsQuery } from "generated/graphql";
import { idUrlMask } from "Globals/Hooks";
import { calendarPath, callCenterSchedulerPath, commandCenterPath, commandCenterPricingPath, commissionRatesPath, contractorRatingSettingsPath, contractorsPath, recoveryCenterPath, recoveryCenterPricingPath, salesPeoplePath } from "Globals/PathStrings";
import { PropsWithChildren } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import AddHardProductPage from "./AddProduct/AddHardProductPage";
import AddSoftProductPage from "./AddProduct/AddSoftProductPage";
import CallCenterRouter from "./CallCenter/CallCenterRouter";
import CommandCenterPage from "./CommandCenter/CommandCenterPage";
import { CommandCenterPricingCenterView, RecoveryCenterPricingCenterView } from "./CommandCenter/PricingCenter/PricingCenter";
import ContractorRatingParameters from "./EmployeeManagement/ContractorProfile/ContractorRatingParameters";
import ContractorsPage from "./EmployeeManagement/ContractorProfile/ContractorsPage";
import SalespeoplePage from "./EmployeeManagement/SalesPersonProfile/SalespeoplePage";
import FinancingPage from "./Financing/FinancingPage";
import InventoryManagement from "./InventoryManagement/InventoryManagement";
import LaborRatesPage from "./LaborRates/LaborRatesPage";
import Ordering from "./Ordering/Ordering";
import PricingControlsPage from "./PricingControls/PricingControlsPage";
import ProductCostsPage from "./ProductCosts/ProductCostsPage";
import ProductPricingPage from "./ProductPricing/ProductPricingPage";
import ProjectCoordinatorPage from "./ProjectManagement/Dashboard/ProjectCoordinatorPage";
import InstallationCalendarPage from "./ProjectManagement/InstallationCalendar/InstallationCalendarPage";
import ProjectsPage from "./ProjectManagement/ProjectsPage";
import RecoveryCenterPage from "./RecoveryCenter/RecoveryCenterPage";
import ServiceAreasPage from "./SAHScheduling/ServiceAreasPage/ServiceAreasPage";
import ServiceProductPricing from "./ServiceProductPricing/ServiceProductPricing";
import TestDataCleanupPage from "./TestDataCleanupPage";
import BlockAppointmentTimesPage from "./TimeSlotBlocking/BlockAppointmentTimesPage";
import ViewBlockedAppointmentTimesPage from "./TimeSlotBlocking/ViewBlockedAppointmentTimesPage";
import CommissionRates from "./EmployeeManagement/Commission/CommissionRates";

function Section({ title, children }: PropsWithChildren<{ title: string }>) {
    return (
        <div
            className="fill-width flex-column"
            style={{ alignItems: "flex-start" }}
        >
            <h3>{title}</h3>
            <div className="horizontal-bar" />
            <div
                className="fill-width flex-row padding-sm children-margin-top-xxsm"
                style={{ flexWrap: "wrap" }}
            >
                {children}
            </div>
        </div>
    );
}

function AdminPage() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { data } = useGetAuthenticatedWorkerPermissionsQuery();

    return (
        <NavbarPage
            title={"Admin"}
            padContent
            centerHorizontally
        >
            <Container className="flex-column">
                <Section title="Project Management">
                    {/* <SpacedButton
            onClick={() => history.push(path + "/inventory-management")}
            variant="contained"
          >
            Inventory Management
          </SpacedButton>
          <SpacedButton
            onClick={() => history.push(path + "/ordering")}
            variant="contained"
          >
            Place Order
          </SpacedButton> */}
                    <SpacedButton
                        onClick={() => history.push(path + "/projects")}
                        variant="contained"
                    >
                        Projects
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/project-management")}
                        variant="contained"
                    >
                        Project Coordinator Dashboard
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/command-center")}
                        variant="contained"
                    >
                        Command Center
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/recovery-center")}
                        variant="contained"
                    >
                        Recovery Center
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/call-center")}
                        variant="contained"
                        hidden={!(data?.authenticatedWorkerPermissions?.callCenterWorker ?? false)}
                    >
                        Call Center
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(calendarPath)}
                        variant="contained"
                    >
                        Installation Calendar
                    </SpacedButton>
                </Section>
                <Section title="Scheduling">
                    <SpacedButton
                        onClick={() => history.push(callCenterSchedulerPath)}
                        variant="contained"
                        hidden={!(data?.authenticatedWorkerPermissions?.callCenterWorker ?? false)}
                    >
                        Schedule New Appointment
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/block-appointment-times")}
                        variant="contained"
                    >
                        Block Appointment Times
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/view-blocked-appointment-times")}
                        variant="contained"
                    >
                        View Blocked Appointment Times
                    </SpacedButton>

                    <SpacedButton
                        onClick={() => history.push(path + "/service-areas")}
                        variant="contained"
                    >
                        View/Edit Service Area
                    </SpacedButton>
                </Section>

                <Section title="Pricing">
                    <SpacedButton
                        onClick={() => history.push(path + "/labor-rates")}
                        variant="contained"
                    >
                        Labor Rates
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/product-costs")}
                        variant="contained"
                    >
                        Product Costs
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/product-pricing")}
                        variant="contained"
                    >
                        Product Pricing
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/financing")}
                        variant="contained"
                    >
                        Financing
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/accessory-product-pricing")}
                        variant="contained"
                    >
                        Accessory Product Pricing
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/pricing-controls")}
                        variant="contained"
                    >
                        Pricing Controls
                    </SpacedButton>
                </Section>
                <Section title="Employee Management">
                    <SpacedButton
                        onClick={() => history.push(contractorsPath)}
                        variant="contained"
                    >
                        Contractors
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(contractorRatingSettingsPath)}
                        variant="contained"
                    >
                        Contractor Rating Settings
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(salesPeoplePath)}
                        variant="contained"
                    >
                        Sales Agents
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(commissionRatesPath)}
                        variant="contained"
                    >
                        Commission Rates
                    </SpacedButton>
                </Section>
                <Section title="Inventory">
                    <SpacedButton
                        onClick={() => history.push(path + "/add-hard-surface")}
                        variant="contained"
                    >
                        Add Hard Surface
                    </SpacedButton>
                    <SpacedButton
                        onClick={() => history.push(path + "/add-soft-surface")}
                        variant="contained"
                    >
                        Add Soft Surface
                    </SpacedButton>
                </Section>
                <Section title="DANGER ZONE">
                    <SpacedButton
                        onClick={() => history.push(path + "/DANGER/cleanup")}
                        variant="contained"
                    >
                        Clean Up Test Data
                    </SpacedButton>
                </Section>
            </Container>
        </NavbarPage>
    );
}

export default function Admin() {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/inventory-management`}>
                <InventoryManagement />
            </Route>
            <Route path={`${path}/ordering`}>{<Ordering />}</Route>
            <Route path={`${path}/projects`}>{<ProjectsPage />}</Route>
            <Route path={`${path}/project-management`}>{<ProjectCoordinatorPage />}</Route>
            <Route path={commandCenterPricingPath + idUrlMask}>{<CommandCenterPricingCenterView />}</Route>
            <Route path={commandCenterPath}>{<CommandCenterPage />}</Route>
            <Route path={recoveryCenterPricingPath + idUrlMask}>{<RecoveryCenterPricingCenterView />}</Route>
            <Route path={recoveryCenterPath}>{<RecoveryCenterPage />}</Route>
            <PermissionRoute
                permissions={["callCenterWorker"]}
                path={`${path}/call-center`}
            >
                <CallCenterRouter />
            </PermissionRoute>
            <Route path={`${path}/labor-rates`}>{<LaborRatesPage />}</Route>
            <Route path={`${path}/product-costs`}>{<ProductCostsPage />}</Route>
            <Route path={`${path}/product-pricing`}>{<ProductPricingPage />}</Route>
            <Route path={`${path}/accessory-product-pricing`}>{<ServiceProductPricing />}</Route>
            <Route path={`${path}/pricing-controls`}>{<PricingControlsPage />}</Route>
            <Route path={`${path}/financing`}>{<FinancingPage />}</Route>
            <Route path={`${path}/add-hard-surface`}>{<AddHardProductPage />}</Route>
            <Route path={`${path}/add-soft-surface`}>{<AddSoftProductPage />}</Route>
            <Route path={`${path}/DANGER/cleanup`}>{<TestDataCleanupPage />}</Route>
            <Route path={`${path}/block-appointment-times`}>{<BlockAppointmentTimesPage />}</Route>
            <Route path={`${path}/view-blocked-appointment-times`}>
                {<ViewBlockedAppointmentTimesPage />}
            </Route>
            <Route path={`${path}/service-areas`}>{<ServiceAreasPage />}</Route>
            {/* <Route path={`${path}/projects/pricing-center`}>{<PricingCenter />}</Route> */}
            <Route path={calendarPath}>
                <InstallationCalendarPage />
            </Route>
            <Route path={commissionRatesPath}>
                <CommissionRates />
            </Route>
            <Route path={`${contractorRatingSettingsPath}`}>
                <ContractorRatingParameters />
            </Route>
            <Route path={`${contractorsPath}`}>
                <ContractorsPage />
            </Route>
            <Route path={`${salesPeoplePath}`}>
                <SalespeoplePage />
            </Route>
            <Route
                path={`${path}`}
                component={AdminPage}
            ></Route>
        </Switch>
    );
}
