import { Button, Typography } from "@material-ui/core";
import FlatSection from "../../../../../../FlatComponents/Layout/FlatSection";
import { formatAsIntOrNDecimals } from "../../../../../../Globals/StringFormatting";
import { CommissionBreakdownTableProps, makeCommissionMaterialBreakdownRows } from "./TableUtils";
import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import TableModal from "./TableModal";


interface EditButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    icon?: React.ElementType
}


export default function MaterialTable({ title, areaBreakdownsForCommission }: CommissionBreakdownTableProps) {

   

    const handleEditClick = (data: { areaId: number; lineNo: number; jobConfigId: number; title: string }) => {
        setModalState({ ...data, show: true });
    };


    const handleClose = () => {
        setModalState({ show: false });
    };


    const [modalState, setModalState] = useState<{
        show: boolean;
        areaId?: number;
        lineNo?: number;
        jobConfigId?: number;
        title?: string;
    }>({
        show: false,
    });

    

    const commissionBreakdownsRows = makeCommissionMaterialBreakdownRows(areaBreakdownsForCommission);


    if (commissionBreakdownsRows.length <= 0)
        return <Typography>No area or product information to show</Typography>;

    return (
        <FlatSection centerAdornment={title ?? "Materials"} className="flat-job-detail-table" removePadding={true}>
            <table>
                <thead>
                    <tr>
                        <th colSpan={2 }></th>
                        <th className="px-2">QTY</th>
                        <th className="text-center">Product</th>                       
                        <th className="text-center px-3">Unit Cost</th>
                        <th className="px-2">Total</th>
                        <th></th>
                        


                    </tr>
                </thead>
                <tbody>
                    {commissionBreakdownsRows.map((row, index) => (
                        

                        row.shippingIdentifier ? (

                            <>
                                <tr>
                                    <td>{row.line}</td>
                                    <td>Misc</td>
                                    <td colSpan={3}>{row.misc}</td>
                                    <td> ${row.miscAmount ?? 0}</td>
                                    <td className="text-end p-0 m-0">

                                        <EditButton onClick={() => {
                                            handleEditClick({
                                                areaId: row.areaId??0,
                                                lineNo: row.lineNo ?? 0,
                                                jobConfigId: row.jobConfigId??0,
                                                title:"Misc"
                                        });  }}/>

                                     </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} style={{ height: "1.8em" }}></td>
                                </tr>
                                <tr key={index}>

                                    <td>{row.line}</td>
                                    <td >Shipping</td>
                                    <td></td>
                                    <td colSpan={2}></td>
                                    <td>${row.shipping ?? 0}</td>
                                    <td className="text-end p-0 m-0">

                                        <Button size="small" onClick={() => {
                                            handleEditClick({
                                                areaId: row.areaId ?? 0,
                                                lineNo: row.lineNo ?? 0,
                                                jobConfigId: row.jobConfigId ?? 0,
                                                title: "Shipping"
                                        });  }}>
                                            <EditIcon fontSize={"small"} />
                                        </Button>
                                    </td>

                                </tr>
                            </>) :

                            <tr key={index}>
                                <td className="p-1">{row.line}</td>
                                <td>Material</td>

                                <td>{formatAsIntOrNDecimals(row.totalSqft)}</td>
                                <td>{row.productType} {row.style}-{row.color}</td>
                                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.unitCost)}</td>
                                <td >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.totalCost)}</td>
                                <td></td>


                            </tr>

                    ))}

                </tbody>

            </table>
            <TableModal
                show={modalState.show}
                handleClose={handleClose}
                title={modalState.title}
                areaId={modalState.areaId}
                lineNo={modalState.lineNo}
                jobConfigId={modalState.jobConfigId}
            />
        </FlatSection>
    );
}

const EditButton = ({ onClick, icon: Icon = EditIcon }:EditButtonProps ) => {
    return (
        <Button size="small" onClick={onClick}>
            <Icon fontSize={"small"} />
        </Button>
    )
}                           