import { isNotNullOrUndefined, isNullOrUndefined } from "../../../../../../Globals/GenericValidators";
import { getNameOfArea } from "../../../../../../Redux/JobReducerDataStructures/AreaType";
import { GetJobBreakdownForCommissionQuery, LabelForRoom, } from "../../../../../../generated/graphql";



export type CommissionBreakdowns = GetJobBreakdownForCommissionQuery['jobBreakdownForCommission']['areaBreakdownsForCommission'];

export type AreaServices = GetJobBreakdownForCommissionQuery['jobBreakdownForCommission']['areaBreakdownsForCommission'][number]['services']

export interface ServiceMaterialGroupsComm {
    [key: number]: { name: string, pricingUnit: string, materialAmount: number, costPerUnit:number };
}
export interface CommissionBreakdownTableProps {
    title?: string
    areaBreakdownsForCommission: CommissionBreakdowns;

}

export interface CommissionMaterialBreakdownRowProps {
    line?: number;
    productType: string;    
    totalSqft: number;
    style?: string;
    color?: string;
    unitCost: number;
    totalCost: number;
    areaId?: number;
    jobConfigId?: number;
    shippingIdentifier?: boolean
    lineNo?: number;
    misc?: string | null;
    miscAmount?: number | null;
    shipping?: number | null,
  
}

function groupServiceByMaterialCategoryComm(services: AreaServices, forWorkOrder = false) {

    let filtered = services.filter(s => (isNotNullOrUndefined(s.materialCategoryId) && s.materialCategoryId! > 0 && s.isActive));

    if (forWorkOrder) { filtered = filtered.filter(s => !s.customerDoesService) }

    let groups: ServiceMaterialGroupsComm = {};

    filtered.forEach(s => {
        if (Object.keys(groups).includes(`${s.materialCategoryId!}`)) {
            groups[s.materialCategoryId!]!.materialAmount += s.materialAmount!;
        } else {
            groups[s.materialCategoryId!] = { name: s.materialCategoryName!, pricingUnit: s.materialCategoryPriceUnit!, materialAmount: s.materialAmount!, costPerUnit: s.materialCostPerUnit! }
        }
    })
    return groups;
}

export function makeCommissionMaterialBreakdownRows(areaBreakdownsForCommission: CommissionBreakdowns): CommissionMaterialBreakdownRowProps[] {
    let rows: CommissionMaterialBreakdownRowProps[] = [];


    areaBreakdownsForCommission.forEach(ab => {
        let primaryProductType = ab.product.productType
        let lineNo = ab.lineNum;
        

        let primaryRow: CommissionMaterialBreakdownRowProps = {
            line: lineNo,
            productType: primaryProductType,
            totalSqft: ab.productSqft,
            style: ab.product.productStyle,
            color: ab.product.productColor,
            unitCost: ab.productCostPerSqft,
            totalCost: ab.totalProductCost,
            areaId: ab.areaId,
            jobConfigId: ab.jobConfigWithCommissionForMaterial.jobConfigId
            
            
        };

        rows.push(primaryRow);
        

        let testRow: CommissionMaterialBreakdownRowProps = {

            productType: "Miscellaneous",
            lineNo: ab.jobConfigWithCommissionForMaterial.lineNo,
            misc: ab.jobConfigWithCommissionForMaterial.misc,
            shipping: ab.jobConfigWithCommissionForMaterial.shipping,
            miscAmount: ab.jobConfigWithCommissionForMaterial.miscAmount,
            shippingIdentifier: true,
            totalCost: 0,
            unitCost: 0,
            totalSqft: 0,
            areaId: ab.areaId,
            jobConfigId: ab.jobConfigWithCommissionForMaterial.jobConfigId
        }

        let materialGroupedServices = groupServiceByMaterialCategoryComm(ab.services);
        
        Object.keys(materialGroupedServices).forEach(categoryId => {
            let group = materialGroupedServices[+categoryId];
            let secondaryRow: CommissionMaterialBreakdownRowProps;

            if (group.name.includes("Under")) {
                secondaryRow = {
                    productType: "Underlayment",
                    totalSqft: group.materialAmount,
                    unitCost: group.costPerUnit,
                    totalCost: group.costPerUnit * group.materialAmount


                };
            }
            else {
                secondaryRow = {
                    productType: group.name,
                    totalSqft: group.materialAmount,
                    unitCost: group.costPerUnit,
                    totalCost: group.costPerUnit * group.materialAmount
                }
            }
            rows.push(secondaryRow)
            rows.push(testRow)
        });

    })


    return rows;
}
interface ServiceGroupAmount {
    serviceType: string;
    laborAmount: number;
    labourPricePerUnit:number,
    laborUnit: string
    description: string;
    labels: LabelForRoom[];
}
interface WhoDoesGroupByParty {
    WOF?: ServiceGroupAmount;
    Cust?: ServiceGroupAmount;
    None?: ServiceGroupAmount;
    OverExisting?: ServiceGroupAmount;
}
export interface WhoDoesServiceGroups {
    [jobServiceId: number]: WhoDoesGroupByParty
}
function groupServicesByWhoDoes(services: AreaServices): WhoDoesServiceGroups {
    let groupings: WhoDoesServiceGroups = {};

    services.forEach(s => {
        let jsId = s.jobServiceId;
        let whoDoes: keyof WhoDoesGroupByParty = s.serviceDescription === "None" ? "None" : (s.customerDoesService ? "Cust" : "WOF");
        let laborAmnt = s.laborAmount;     
        

        if (Object.keys(groupings).includes(jsId.toString())) {
            let jobServiceEntry = groupings[jsId];
            if (jobServiceEntry[whoDoes] !== undefined) {
                jobServiceEntry[whoDoes]!.laborAmount += laborAmnt;

                const labelsToAdd = s.room.labels.filter(label => isNullOrUndefined(jobServiceEntry[whoDoes]!.labels.find(existingLabel => existingLabel.id === label.id)))
                jobServiceEntry[whoDoes]!.labels = jobServiceEntry[whoDoes]!.labels.concat(labelsToAdd);
            } else {
                jobServiceEntry[whoDoes] = {
                    serviceType: s.serviceType,
                    laborAmount: laborAmnt,
                    labourPricePerUnit: s.laborPricePerUnit,
                    laborUnit: s.laborPriceUnit,
                    description: s.serviceDescription,
                    labels: s.room.labels
                }
            }
        } else {
            groupings[jsId] = {
                [whoDoes]: {
                    serviceType: s.serviceType,
                    laborAmount: laborAmnt,
                    labourPricePerUnit: s.laborPricePerUnit,
                    laborUnit: s.laborPriceUnit,
                    description: s.serviceDescription,
                    labels: s.room.labels
                }
            };
        }

    });
    return groupings;
}

export interface LabourCommissionBreakdownRowProps {
    line?:number,
    type: string,
    amount: number,
    amountUnit: string,
    description: string,
    area: string;
    unitCost?: number;
    total: number;
    misc?: string |null;
    miscAmount?: number |null;    
    shippingIdentifier?: boolean;
    areaId?: number;
    jobConfigId?: number;

}

export function makeCommissionLaborBreakdownRows(areaBreakdownsForCommission: CommissionBreakdowns, forWorkOrder = false): LabourCommissionBreakdownRowProps []{
    let rows: LabourCommissionBreakdownRowProps[] = [];
   
   

    areaBreakdownsForCommission.forEach(ab => {
        let areaRows: LabourCommissionBreakdownRowProps[] = [];        
        let lineNo = ab.lineNum;
        let installationRow: LabourCommissionBreakdownRowProps | undefined = undefined;
        

        let groupedServices = groupServicesByWhoDoes(ab.services);

        Object.keys(groupedServices).forEach(jsId => {

            let serviceForWOF = groupedServices[+jsId]['WOF'];

            if (isNotNullOrUndefined(serviceForWOF)) {

                let descriptionStr = serviceForWOF!.description;
              

                if (!forWorkOrder) { descriptionStr += " - WOF"; }

                let serviceForWOFRow: LabourCommissionBreakdownRowProps = {
                    line: lineNo,
                    type: serviceForWOF!.serviceType,
                    amount: serviceForWOF!.laborAmount,
                    amountUnit: serviceForWOF!.laborUnit,
                    description: descriptionStr,
                    area: getNameOfArea(serviceForWOF!.labels),
                    unitCost: serviceForWOF!.labourPricePerUnit,
                    total: serviceForWOF!.laborAmount * serviceForWOF!.labourPricePerUnit
                }

                if (serviceForWOF!.serviceType === "Installation") {
                    serviceForWOFRow.type = `${ab.product.productType} Installation`
                    installationRow = serviceForWOFRow;

                } else {
                    areaRows.push({...serviceForWOFRow, line: undefined });
                }
            }

            const serviceForOverExisiting = groupedServices[+jsId]["OverExisting"];
            if (isNotNullOrUndefined(serviceForOverExisiting)) {
                let descriptionStr = serviceForOverExisiting!.description + "- Over Existing";
                let serviceForOverExistingRow: LabourCommissionBreakdownRowProps = {
                    line: lineNo,
                    type: serviceForWOF!.serviceType,
                    amount: serviceForWOF!.laborAmount,
                    amountUnit: serviceForWOF!.laborUnit,
                    description: descriptionStr,
                    area: getNameOfArea(serviceForWOF!.labels),
                    unitCost: serviceForWOF!.labourPricePerUnit,
                    total: serviceForWOF!.laborAmount * serviceForWOF!.labourPricePerUnit
                }
                areaRows.push({...serviceForOverExistingRow, line: undefined });
            }
            if (!forWorkOrder) {
                let serviceForCust = groupedServices[+jsId]['Cust'];
                if (isNotNullOrUndefined(serviceForCust)) {
                    let serviceForCustRow: LabourCommissionBreakdownRowProps = {
                        line: lineNo,
                        type: serviceForCust!.serviceType,
                        amount: serviceForCust!.laborAmount,
                        amountUnit: serviceForCust!.laborUnit,
                        description: `${serviceForCust!.description} - CUST`,
                        area: getNameOfArea(serviceForCust!.labels),
                        unitCost: serviceForCust!.labourPricePerUnit,
                        total: serviceForCust!.laborAmount * serviceForWOF!.labourPricePerUnit
                    };
                    if (serviceForCust!.serviceType === "Installation") {
                        installationRow = serviceForCustRow;
                    } else {
                        areaRows.push({...serviceForCustRow, line: undefined })
                    }
                }
            }
        });

        ab.customServices.forEach(cs => {
            const roomsForService = ab.rooms?.filter(r => cs.roomIds.includes(r.id)) ?? [];
            const roomLabels = roomsForService.flatMap(rfs => rfs.labels);
            let description = cs.description;
            

            if (!forWorkOrder) description += " - WOF";

            areaRows.push({
                line: undefined,
                type: "Custom",
                amount: 0,
                amountUnit: "",
                description: description,
                area: getNameOfArea(roomLabels),
                total: 0
            });
        });

        if (isNotNullOrUndefined(installationRow)) {
            areaRows.unshift(installationRow!)
        }
        rows.push(...areaRows);

        let labourRow: LabourCommissionBreakdownRowProps = {
            line: lineNo,
            type: "Miscellaneous",
            amount: 0,
            amountUnit: "",
            area: "",
            description: "",
            unitCost: 0,
            total: 0,
            misc: ab.jobConfigWithCommissionForLabour.misc,
            miscAmount: ab.jobConfigWithCommissionForLabour.miscAmount,
            shippingIdentifier: true,
            areaId: ab.areaId,
            jobConfigId: ab.jobConfigWithCommissionForLabour.jobConfigId

        }
        rows.push(labourRow)

    })
   
    return rows;
    
    
}

