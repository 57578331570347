import {
    AppliedDiscount,
    CommissionRate,
    GetPricingSummaryQuery,
    GetPromotionOnJobQuery,
    JobConfigurationPriceUpdatedDocument,
    JobConfigurationPriceUpdatedSubscription, PriceOverrideSentDocument,
    PriceOverrideSentSubscription,
    PriceRequestUpdatedForConfigDocument,
    PriceRequestUpdatedForConfigSubscription,
    useGetPricingSummaryQuery
} from "generated/graphql";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
    selectCommissionRates,
    selectJobConfigurationId,
    selectJobCost,
    selectMSRP,
    selectPreOverridePrice,
    selectTotalPrice,
    setCommissionRates,
    setJobCost,
    setMSRP,
    setOverride,
    setPreOverridePrice,
    setPriceRequest,
    setTotalPrice
} from "Redux/pricingCenterReducer";
import AppliedDiscountSection from "./AppliedDiscountSection";
import FinalPriceSection from "./FinalPriceComponents/FinalPriceSection";
import FinancingSection from "./Financing/FinancingSection";
import { calculateMargin } from "./marginUtils";
import PreOverridePriceSection from "./PreOverridePriceSection";
import PriceRequestSection from "./PriceRequestSection";
import { Breakdowns } from "Pages/Admin/ProjectManagement/Dashboard/Breakdown/BreakdownTableUtils";
import BreakdownHover from "./BreakdownHover";

export function getCommissionRate(rates: CommissionRate[], margin: number) {
    return rates.find((r) => r.lowerMargin <= margin && r.upperMargin >= margin)?.rate ?? 0;
}

interface PricingSummaryProps {
    discounts: AppliedDiscount[];
    listenForDiscountUpdates: () => void;
    promotion: GetPromotionOnJobQuery['promotionOnJob'] | undefined;
    listenForPromotionUpdates: () => void;
    areaBreakdowns: Breakdowns;
}

export default function PricingSummary({
    discounts,
    listenForDiscountUpdates,
    promotion,
    listenForPromotionUpdates,
    areaBreakdowns
}: PricingSummaryProps) {
    const dispatch = useAppDispatch();
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);

    const { subscribeToMore: subscribeToSummaryUpdates } = useGetPricingSummaryQuery({
        skip: jobConfigurationId < 1,
        variables: { jobConfigurationId: jobConfigurationId },
        fetchPolicy: "cache-first",
        onCompleted: (data) => {
            dispatch(setPriceRequest(data.jobConfiguration.priceRequest ?? undefined));
            dispatch(setOverride(data.currentPriceOverride ?? undefined));
            dispatch(setJobCost(data.jobConfigurationCost));
            dispatch(setMSRP(data.jobConfiguration.price?.mSRP ?? -1));
            dispatch(setPreOverridePrice(data.totalPriceBeforeOverride));
            dispatch(setTotalPrice(data.jobConfiguration.price!.total));
            dispatch(setCommissionRates(data.currentCommissionRates));
        },
    });
    
    // listen for changes to the pricing of the config
    useEffect(() => {
        return subscribeToSummaryUpdates({
            document: JobConfigurationPriceUpdatedDocument,
            variables: { jobConfigurationId: jobConfigurationId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const updatedPricing = (
                    subscriptionData.data as unknown as JobConfigurationPriceUpdatedSubscription
                ).jobConfigurationPriceUpdated;
                if (!updatedPricing) return prev;
                const updatedSummary: GetPricingSummaryQuery = {
                    ...prev,
                    totalPriceBeforeOverride: updatedPricing.preOverridePrice,
                    jobConfiguration: {
                        ...prev.jobConfiguration,
                        price: updatedPricing.overallPrice,
                    },
                };

                return updatedSummary;
            },
        });
    }, [jobConfigurationId, subscribeToSummaryUpdates]);

    // listen for price requests
    useEffect(() => {
        return subscribeToSummaryUpdates({
            document: PriceRequestUpdatedForConfigDocument,
            variables: { jobConfigurationId: jobConfigurationId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const updatedRequest = (
                    subscriptionData.data as unknown as PriceRequestUpdatedForConfigSubscription
                ).priceRequestUpdatedForConfig;
                if (!updatedRequest) return prev;

                const updatedSummary: GetPricingSummaryQuery = {
                    ...prev,
                    jobConfiguration: {
                        ...prev.jobConfiguration,
                        priceRequest: updatedRequest!,
                    },
                };

                return updatedSummary;
            }
        });
    }, [jobConfigurationId, subscribeToSummaryUpdates]);

    // listen for price overrides that have bee sent
    useEffect(() => {
        return subscribeToSummaryUpdates({
            document: PriceOverrideSentDocument,
            variables: { jobConfigurationId: jobConfigurationId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const updatedOverride = (
                    subscriptionData.data as unknown as PriceOverrideSentSubscription
                ).priceOverrideSent;
                if (!updatedOverride) return prev;

                const updatedSummary: GetPricingSummaryQuery = {
                    ...prev,
                    currentPriceOverride: updatedOverride,
                };

                return updatedSummary;
            }
        });
    }, [jobConfigurationId, subscribeToSummaryUpdates]);

    // TODO: this isn't working, but need to be able to clear the override when the sell sheet rejects the override
    // useEffect(() => {
    //     return subscribeToSummaryUpdates({
    //         document: OverrideDiscountSentDocument,
    //         variables: {jobConfigurationId: jobConfigurationId},
    //         updateQuery: (prev, {subscriptionData}) => {
    //             const incomingData = (subscriptionData.data as unknown as OverrideDiscountSentSubscription).overrideDiscountSent;
    //             console.log(incomingData)
    //             if (!incomingData) { return prev; }
                
    //             if (incomingData.overrideDiscount.id === -1) {
    //                 // the sell sheet rejected the override
    //                 const updatedSummary: GetPricingSummaryQuery = {
    //                     ...prev,
    //                     currentPriceOverride: undefined
    //                 };
    //                 return updatedSummary;
    //             } else {
    //                 return prev; // the override came from this page, so we don't need to get it from the sub
    //             }
    //         }
    //     })
    // }, [jobConfigurationId, subscribeToSummaryUpdates]);

    const msrp = useAppSelector(selectMSRP);
    const jobCost = +(useAppSelector(selectJobCost).toFixed(2));
    const preOverridePrice = useAppSelector(selectPreOverridePrice);
    const totalPrice = useAppSelector(selectTotalPrice);
    const commissionRates = useAppSelector(selectCommissionRates);
    const [showBreakdownHover, setShowBreakdownHover] = useState<boolean>(false);

    return (
        <>
            <div
                className="flat-outer-container-padding flex-row flat-font"
                style={{ alignItems: "center" }}
            >
                <div style={{ fontWeight: "bold", width: "5rem" }}>MSRP</div>

                <div
                    className="highlight-flat-text"
                    style={{ marginRight: "4rem" }}
                    onMouseOver={() => setShowBreakdownHover(true)}
                    onMouseOut={() => setShowBreakdownHover(false)}
                >
                    <NumberFormat
                        prefix="$"
                        thousandSeparator={true}
                        displayType="text"
                        value={msrp}
                        decimalScale={2}
                        fixedDecimalScale
                    />
                    <BreakdownHover jobConfigurationId={jobConfigurationId} show={showBreakdownHover} />
                </div>

                <div
                    className="flat-font-upper"
                    style={{ color: "var(--flat-gray-4)", marginRight: "4rem" }}
                >
                    Margin
                </div>

                {jobCost && (
                    <div>
                        <NumberFormat
                            suffix="%"
                            thousandSeparator={true}
                            displayType="text"
                            value={msrp === 0 ? 0 : calculateMargin(msrp, jobCost)}
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </div>
                )}
            </div>

            <div className="flat-h-bar" />

            <div className="flat-outer-container-padding flex-row flat-font">
                <div style={{ width: "35%" }}>
                    <AppliedDiscountSection
                        discounts={discounts}
                        listenForDiscountUpdates={listenForDiscountUpdates}
                        promotion={promotion}
                        listenForPromotionUpdates={listenForPromotionUpdates}
                    />
                </div>
                <div style={{ width: "65%" }}>
                    <FinancingSection discounts={discounts} />
                </div>
            </div>

            <div className="flat-h-bar" />

            <div className="flat-outer-container-padding flex-column flat-font">
                <div
                    className="flat-font flat-font-bold flat-font-upper"
                    style={{ marginBottom: ".5rem" }}
                >
                    Job Pricing
                </div>
                {jobCost && (
                    <>
                        <div className="flex-row flex-gap-sm align-items-flex-end">
                            <PreOverridePriceSection />
                        </div>
                        <div className="flex-row flex-gap-sm align-items-flex-end">
                            <PriceRequestSection />
                        </div>
                    </>
                )}
            </div>
            
            <div className="flat-h-bar" />
            
            {commissionRates && preOverridePrice && totalPrice && <FinalPriceSection />}
        </>
    );
}
