import { Typography } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { formatAsIntOrNDecimals } from '../../../../../Globals/StringFormatting';

// Define the type for a salesperson
type Salesperson = {
    id: number;
    workerId: number;
    firstName: string;
    lastName: string;
};

// Define the props type
interface HeaderCommisionPageProps {
    salespeople: Salesperson[];
    commissionPercentage: string;
    jobContractTotal: number; 
}

function HeaderCommisionPage({ salespeople, commissionPercentage, jobContractTotal }: HeaderCommisionPageProps) {
    // Parse the commission percentage as a number
    const commissionPercentageNumber = parseFloat(commissionPercentage);

    // Calculate the commission value
    const commissionValue = (commissionPercentageNumber / 100) * jobContractTotal;

    return (
        <Navbar className="bg-body-tertiary">
            <Container fluid>
                <Row style={{ width: "100%" }}>
                    <Col style={{ display: "flex", alignItems: "flex-end" }}>
                        <Typography>Sales Agent Contract Labor - 1099</Typography>
                    </Col>
                    <Col>
                        <Typography variant="h5" align="center">SALES COMMISSION</Typography>
                    </Col>
                    <Col>
                        <Row>
                            {salespeople.map(person => (
                                <Col className="text-end" md={12} key={person.id}>
                                    <Typography>{person.firstName} {person.lastName}</Typography>
                                </Col>
                            ))}
                            {/* Display the commission percentage and calculated value */}
                            <Col className="text-end" md={12}>
                                <Typography>
                                    {commissionPercentageNumber}% - ${formatAsIntOrNDecimals(commissionValue)}
                                </Typography>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
}

export default HeaderCommisionPage;
