import { useGetJobContractQuery, useGetJobInvoiceDataQuery, useGetProjectCoordinatorFromConfigurationQuery } from "generated/graphql";
import { dateTimeStrToDate, todaysDateWithoutTime } from "Globals/DateAndTimeHelpers";
import { isNullOrUndefined } from "Globals/GenericValidators";
import { CASH_PAYMENT_ID, CHECK_PAYMENT_ID, C_C_PAYMENT_ID } from "Globals/globalConstants";
import { useNumericIdParam } from "Globals/Hooks";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectContractIsCancelled, selectDoesContractExist, selectJobConfigurationId, selectJobContractId, selectWithinCancellationWindow, updateContract } from "Redux/jobTrackerReducer";
import countBusinessDaysFrom from "../../../Invoice/InvoiceData";

import { PaymentMethodTypes } from "../../../Invoice/InvoiceTotal";
import JobCancellationButton from "../../JobCancellationButton";
import { ContractPaymentsDialog } from "../../PaymentsDialog";
import "../job-tracker.css";
import GoBackButton from "./GoBackButton";
import JobTrackerCustomerInformation from "./JobTrackerCustomerInformation";
import JobTrackerNavigator from "./JobTrackerNavigator";


function determineIfWithinCancellationPeriod(contractSignedDate: Date) {
    let today = todaysDateWithoutTime();
    let endOfCancellationPeriod = countBusinessDaysFrom(contractSignedDate, 3);
    return today <= endOfCancellationPeriod;
}

// TODO: this information should be loaded from the database; this should be done everywhere that uses the payment dialog
export function paymentMethodIdx2Str(methodId: number): PaymentMethodTypes {
    if (methodId === CASH_PAYMENT_ID) {
        return "cash";
    } else if (methodId === CHECK_PAYMENT_ID) {
        return "check"
    } else if (methodId === C_C_PAYMENT_ID) {
        return "cc";
    }

    throw new Error(`${methodId} is not a valid payment method id`)
}

export function paymentMethodStr2Idx(methodStr: PaymentMethodTypes): number {
    if (methodStr === "cash") {
        return CASH_PAYMENT_ID;
    } else if (methodStr === "check") {
        return CHECK_PAYMENT_ID;
    } else if (methodStr === "cc") {
        return C_C_PAYMENT_ID;
    }

    throw new Error(`${methodStr} is not a valid payment method`)
}

export function JobTrackerHeader() {
    const dispatch = useAppDispatch();

    const { id: jobContractIdParam } = useNumericIdParam();

    var data=useGetJobContractQuery({
        variables: { jobContractId: jobContractIdParam as number },
        skip: isNullOrUndefined(jobContractIdParam),
        onCompleted: (data) => {
            let contractData = data.jobContract;
            if (contractData) {
                const { id, jobConfigurationId, isCancelled, signingDate, sAHNumber } = contractData

                const fullSahNumber = `SAH-${sAHNumber}`
                const contractSigningDate = dateTimeStrToDate(signingDate);
                const isWithingCancellationWindow = contractSigningDate
                    ? determineIfWithinCancellationPeriod(contractSigningDate)
                    : false;

                dispatch(updateContract(id, jobConfigurationId, fullSahNumber, isWithingCancellationWindow, isCancelled, true, contractSigningDate));
            }
        },
        onError() {
            dispatch(updateContract(jobContractIdParam ?? 0, -1, "", false, false, false, undefined));
        }
    });
    console.log(data)

    const contractId = useAppSelector(selectJobContractId);
    const jobConfigurationId = useAppSelector(selectJobConfigurationId);
    const doesContractExist = useAppSelector(selectDoesContractExist);
    const withinCancellationWindow = useAppSelector(selectWithinCancellationWindow);
    const contractIsCancelled = useAppSelector(selectContractIsCancelled);
    
    const { data: projectCoordinatorData } = useGetProjectCoordinatorFromConfigurationQuery(
        {
            variables:
            { jobConfigurationId: jobConfigurationId ?? 0 },
            skip: jobConfigurationId < 1 || !doesContractExist
        }
    )
    const projectCoordinator = projectCoordinatorData?.projectCoordinatorFromConfiguration;

    const { data: jobData } = useGetJobInvoiceDataQuery({
        skip: jobConfigurationId < 1 || !doesContractExist,
        variables: { jobConfigurationId: jobConfigurationId ?? 0 },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-only"
    });

    const customer = jobData?.jobConfigurationHeader.customer;
    const salespeople = jobData?.jobConfigurationHeader.assignedSalespeople;

    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

    function getProjectCoordinatorName() {
        if (projectCoordinator) {
            return `${projectCoordinator!.firstName} ${projectCoordinator!.lastName}`
        } else {
            return "unassigned";
        }
    }

    return (
        <div className="fill-width flex-column">
            <div className="flex-row flex-space-between padding-vertical-sm">
                <GoBackButton />

                {(contractId && withinCancellationWindow && !contractIsCancelled) && <JobCancellationButton />}

                {contractIsCancelled &&
                    <p
                        className="flat-font margin-none bold-text"
                        style={{color: "var(--flat-red)"}}
                    >
                        Contract has been cancelled
                    </p>
                }
            </div>

            <JobTrackerCustomerInformation 
                projectCoordinator={getProjectCoordinatorName()}
                customer={customer}
                salespeople={salespeople}
            />

            <JobTrackerNavigator openPaymentsDialog={() => setPaymentDialogOpen(true)} />

            {paymentDialogOpen && contractId && jobConfigurationId && (
                <ContractPaymentsDialog
                    setOpen={(newOpen) => setPaymentDialogOpen(newOpen)}
                    contractId={contractId}
                    jobConfigurationId={jobConfigurationId}
                />
            )}
        </div>
    )
}