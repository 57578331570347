import { calculateMargin } from "../../../../CommandCenter/PricingCenter/PricingSummary/marginUtils";


export function findCommissionPercentage(revenue: number, cost: number): number {
    if (revenue === 0) {
        return 0; // Avoid division by zero
    }

    const margin = calculateMargin(revenue, cost);

    // Commission percentage logic based on margin ranges
    if (margin < 40) {
        return 5;
    } else if (margin >= 40 && margin < 42) {
        return 6;
    } else if (margin >= 42 && margin <= 44) {
        return 7;
    } else if (margin > 44 && margin <= 46) {
        return 8;
    } else if (margin > 46 && margin <= 48) {
        return 9;
    } else if (margin > 48 && margin <= 50) {
        return 10;
    } else if (margin > 50 && margin <= 52) {
        return 11;
    } else if (margin > 52 && margin <= 54) {
        return 12;
    } else if (margin > 54 && margin <= 56) {
        return 13;
    } else if (margin > 56 && margin <= 58) {
        return 14;
    } else if (margin > 58 && margin <= 60) {
        return 15;
    } else if (margin > 60 && margin <= 62) {
        return 16;
    } else if (margin > 62 && margin <= 64) {
        return 17;
    } else if (margin > 64 && margin <= 66) {
        return 18;
    } else if (margin > 66 && margin <= 68) {
        return 19;
    } else if (margin > 68 && margin <= 70) {
        return 20;
    } else if (margin > 70 && margin <= 72) {
        return 21;
    } else {
        return 0; 
    }
}


