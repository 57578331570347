import { Button, Typography } from "@material-ui/core";
import { CommissionBreakdownTableProps, makeCommissionLaborBreakdownRows } from "./TableUtils";
import FlatSection from "../../../../../../FlatComponents/Layout/FlatSection";
import { formatAsIntOrNDecimals } from "../../../../../../Globals/StringFormatting";
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import TableModalLabour from "./TableModalLabour";
import { namedOperations, useAddMiscAndShippingMutation } from "../../../../../../generated/graphql";

interface EditButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    icon?: React.ElementType
}

export default function LaborBreakdownTable({ title, areaBreakdownsForCommission }: CommissionBreakdownTableProps) {
    useAddMiscAndShippingMutation({
        refetchQueries: [namedOperations.Query.GetJobBreakdownForCommission, namedOperations.Query.GetPricingSummary]
    });
    const breakdownRows = makeCommissionLaborBreakdownRows(areaBreakdownsForCommission);

    const handleEditClick = (data: { areaId: number; lineNo: number; jobConfigId: number; title: string }) => {
        setModalState({ ...data, show: true });
    };


    const handleClose = () => {
        setModalState({ show: false });
    };


    const [modalState, setModalState] = useState<{
        show: boolean;
        areaId?: number;
        lineNo?: number;
        jobConfigId?: number;
        title?: string;
    }>({
        show: false,
    });

    if (breakdownRows.length <= 0)
        return <Typography>No area or product information to show</Typography>;
    
    return (
        <FlatSection centerAdornment={title ?? "Labor"} className="flat-job-detail-table" removePadding={true}>
            <table>
                <thead>
                    <tr>
                        <td>Line</td>
                        <td>Type</td>
                        <td>Quantity</td>
                        <td>Description</td>
                        <td>Unit Price</td>
                        <td>Total</td>
                    </tr>
                </thead>
                <tbody>
                    {breakdownRows.map(
                        (row, index) => (
                            row.shippingIdentifier ? (

                                <>
                                    <tr>
                                        <td>{row.line}</td>
                                        <td>Misc</td>
                                        <td colSpan={3}>{row.misc}</td>
                                        <td> ${row.miscAmount ?? 0}</td>
                                        <td className="text-end p-0 m-0">

                                            <EditButton onClick={() => {
                                                handleEditClick({
                                                    areaId: row.areaId ?? 0,
                                                    lineNo: row.line ?? 0,
                                                    jobConfigId: row.jobConfigId??0,
                                                    title: "Misc"
                                                });
                                            }} />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} style={{ height: "1.8em" }}></td>
                                    </tr>
                                    
                                </>) :
                            <tr key={index}>
                                <td>{row.line}</td>
                                <td>{row.type}</td>
                                <td>{row.amount ? formatAsIntOrNDecimals(row.amount) : "N/A"} {row.amountUnit !== "each" ? row.amountUnit : ""}</td>
                                <td>{row.description}</td>
                                <td>{row.unitCost !== undefined && row.unitCost !== null ? `$${formatAsIntOrNDecimals(row.unitCost)}` : ""}</td>
                                <td>{`$${formatAsIntOrNDecimals(row.total)}`}</td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
            <TableModalLabour
                show={modalState.show}
                handleClose={handleClose}
                title={modalState.title}
                areaId={modalState.areaId}
                lineNo={modalState.lineNo}
                jobConfigId={modalState.jobConfigId}
            />
        </FlatSection>
    );
}
const EditButton = ({ onClick, icon: Icon = EditIcon }: EditButtonProps) => {
    return (
        <Button size="small" onClick={onClick}>
            <Icon fontSize={"small"} />
        </Button>
    )
}  
