import CustomerNamePage from "./SalesCommission/CustomerNamePage";
import FinancePage from "./SalesCommission/FinancePage";
import HeaderCommisionPage from "./SalesCommission/HeaderCommissionPage";
import { Row, Col } from 'react-bootstrap';
import MaterialLaborCommisionBreakdown from "./SalesCommission/MaterialLaborCommisionBreakdown";
import { useGetCommisionHeaderDetailsQuery, useGetContractIdQuery, useGetJobContractQuery, useGetJobInvoiceDataQuery, useGetPricingSummaryQuery } from "../../../../generated/graphql";
import { findCommissionPercentage } from "./SalesCommission/Utils/CommissionRangeCalculation";
import { useParams } from "react-router-dom";



export default function QuotePage() {
    

    const {id1: id } = useParams<any>();

    const jobConfigId = parseInt(id, 10);
    

    const { data, loading, error } = useGetCommisionHeaderDetailsQuery({
        variables: { jobConfigurationId: jobConfigId },
        skip: !jobConfigId
    });
    const { data: invoiceData, loading: loadingInvoiceData, error: errorInvoiceData } = useGetJobInvoiceDataQuery({
        variables: { jobConfigurationId: jobConfigId },
        skip: !jobConfigId
    })
    const { data: contractIdData } = useGetContractIdQuery({
        variables: { jobConfigurationId: jobConfigId },
        skip: !jobConfigId,
    });

    let contractId:number = -1;

    if (contractIdData?.contractId) {
        contractId = contractIdData.contractId;
    }


    const { data: pricingData, loading: loadingPricingData, error: errorData } = useGetPricingSummaryQuery({
        variables: { jobConfigurationId: jobConfigId },
        skip: !jobConfigId
    });

    const { data: contractData, loading: loadingContract, error: errorContact } = useGetJobContractQuery({//async
        variables: { jobContractId: contractId },
        skip: contractId === -1,
        
    });


    if (loading || loadingPricingData || loadingContract || loadingInvoiceData) return <p>Loading...</p>;
    if (error || errorData || errorContact || errorInvoiceData) return <p>Error loading data</p>;

    if (!data || !data.jobConfigurationHeader || !pricingData || !pricingData.jobConfigurationCost || !contractData || !contractData.jobContract.totalPrice) {
        return <p>No data available</p>;
    }
    const { jobConfigurationHeader } = data;

    const customer = jobConfigurationHeader.customer;
    const salespersons = jobConfigurationHeader.assignedSalespeople;
    const SahNumber = jobConfigurationHeader.appointmentNumber;
    
    const materialAndLabourCost = pricingData?.jobConfigurationCost;
    const jobContractTotal = contractData.jobContract.totalPrice;
    const financingTerm = invoiceData?.jobConfiguration.financing?.financingOption?.term;
    const commissionPercentage = `${findCommissionPercentage(jobContractTotal, materialAndLabourCost)}%`;


    

    return (
        <>

            <div>

                <div>
                    <HeaderCommisionPage salespeople={salespersons} commissionPercentage={commissionPercentage} jobContractTotal={jobContractTotal} />
                </div>
                <hr style={{ border: "1px solid black", margin: "0" }} />
                <hr style={{ border: "1px solid black", margin: "0" }} />
                <div>
                    <CustomerNamePage financingTerm={financingTerm} jobContractTotal={jobContractTotal} customer={customer} SahNumber={SahNumber} />
                </div>
                <hr style={{ border: "1px solid black", margin: "0" }} />
                <hr style={{ border: "1px solid black", margin: "0" }} />
                <div> 

                    <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col><FinancePage materialAndLabourCost={materialAndLabourCost} commissionPercentage={commissionPercentage} jobContractTotal={jobContractTotal}  /></Col>

                    </Row>

                </div>

            </div>

            <div>
                <div><MaterialLaborCommisionBreakdown jobConfigId={jobConfigId} /></div>
            </div>

        </>
    )
}