import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { namedOperations, useUpdateJobConfigurationMiscMutation } from '../../../../../../generated/graphql';
import { useState } from 'react';

interface TableModalLabourProps {
    show: boolean;
    handleClose: () => void;
    title?: string;
    areaId?: number;
    lineNo?: number;
    jobConfigId?: number;
}

function TableModalLabour({ show, handleClose, title, areaId, lineNo, jobConfigId }: TableModalLabourProps) {
    const [updateJobConfigWithMisc, { loading }] = useUpdateJobConfigurationMiscMutation({
        refetchQueries: [namedOperations.Query.GetJobBreakdownForCommission,namedOperations.Query.GetPricingSummary]
    });

    // State to handle the total amount and description
    const [amount, setAmount] = useState<string>('');
    const [miscText, setMiscText] = useState<string>(''); // State for Misc text

    async function handleSave() {
        if (!areaId || !lineNo || !jobConfigId) {
            console.error('Missing required data: areaId, lineNo, or jobConfigId');
            return;
        }

        // Parse amount only if it is provided
        const parsedAmount = amount ? parseFloat(amount) : undefined;

        // Ensure valid input
        if (amount && (isNaN(parsedAmount!) || parsedAmount! < 0)) {
            alert('Please enter a valid positive number.');
            return;
        }

        try {
            await updateJobConfigWithMisc({
                variables: {
                    input: {
                        areaId,
                        jobConfigId,
                        lineNo,
                        miscAmount: title === 'Misc' ? parsedAmount : undefined, // Allow undefined
                        misc: title === 'Misc' ? miscText || undefined : undefined, // Always include miscText if provided
                        breakdownType: 'Labour',
                    }
                }
            });
            setAmount('');
            setMiscText('');
            handleClose();
        } catch (err) {
            console.error(`Error saving ${title} data:`, err);
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{title} Amount</Form.Label>
                        <Form.Control
                            type="string"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </Form.Group>
                    {title === 'Misc' && (
                        <Form.Group className="mb-3">
                            <Form.Label>Misc Description</Form.Label>
                            <Form.Control
                                type="text"
                                value={miscText}
                                onChange={(e) => setMiscText(e.target.value)}
                                placeholder="Enter miscellaneous description"
                            />
                        </Form.Group>
                    )}
                    <Button
                        variant="primary"
                        type="button"
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {loading ? 'Saving....' : 'Save'}
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TableModalLabour;
