import { Container, Typography } from "@material-ui/core";
import { Row, Col } from 'react-bootstrap';
import { formatAsIntOrNDecimals } from "../../../../../Globals/StringFormatting";
import NumberFormat from "react-number-format";
import { calculateMargin } from "../../../CommandCenter/PricingCenter/PricingSummary/marginUtils";


interface FinancePageProps {
    materialAndLabourCost: number,
    commissionPercentage: string;
    jobContractTotal: number;
}

function FinancePage({ materialAndLabourCost, commissionPercentage, jobContractTotal }: FinancePageProps) {

    const commissionPercentageNumber = parseFloat(commissionPercentage);
    const commissionValue = (commissionPercentageNumber / 100) * jobContractTotal;
    
    return (

        <Container >
            <Row style={{ width: "100%" }}>

                <Col className="text-end">

                    <Typography>Fin cost</Typography>

                </Col>
                <Col></Col>
                <Col className="text-end">
                <Typography>$0</Typography>
                        
                    </Col>
            </Row>
            <Row style={{ width: "100%" }}>

                <Col>

                    <Typography>Total w/o Fin</Typography>


                </Col>
                <Col></Col>
                <Col className="text-end">
                    <Typography>$0</Typography>

                </Col>
            </Row>
            <Row style={{ width: "100%" }}>

                <Col>

                    <Typography>Material/Labour</Typography>


                </Col>
                <Col></Col>
                <Col className="text-end">
                    <Typography>{`$${formatAsIntOrNDecimals(materialAndLabourCost)}`} </Typography>

                </Col>
            </Row>
            <Row style={{ width: "100%" }}>

                <Col>

                    <Typography>C/C Charge</Typography>


                </Col>
                <Col></Col>
                <Col className="text-end">
                    <Typography>$0</Typography>

                </Col>
            </Row>
            <Row style={{ width: "100%" }}>

                <Col>

                    <Typography>G-margin</Typography>


                </Col>
                <Col className="text-center">
                    {jobContractTotal && materialAndLabourCost ? (
                        <NumberFormat
                            suffix="%"
                            thousandSeparator={true}
                            displayType="text"
                            value={
                                jobContractTotal === 0
                                    ? 0
                                    : calculateMargin(jobContractTotal, materialAndLabourCost)
                            }
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    ) : (
                        "N/A"
                    )}
                </Col>

                <Col className="text-end">
                    {jobContractTotal !== undefined && materialAndLabourCost !== undefined ? (
                        <Typography>
                            <NumberFormat
                                prefix="$"
                                value={jobContractTotal - materialAndLabourCost}
                                displayType="text"
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </Typography>
                    ) : (
                        <Typography>N/A</Typography>
                    )}
                </Col>

            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col md={7 }>
                            <Row>
                                <Col>Commission</Col>
                                <Col className="text-end" style={{ borderRight: "1px solid black" }}>{commissionPercentage}</Col>
                            </Row>
                            <Row>
                                <Col>Self-Gen</Col>
                                <Col className="text-end" style={{ borderRight: "1px solid black" }}>0%</Col>
                            </Row>
                            <Row>
                                <Col>PromoExtra</Col>
                                <Col className="text-end" style={{ borderRight: "1px solid black" }}>0%</Col>
                            </Row>

                        </Col>
                        <Col style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h5"> ${formatAsIntOrNDecimals(commissionValue)}</Typography>
                        </Col>

                    </Row>
                </Col>
            </Row>
            </Container>
        
    );
}

export default FinancePage;