import FlatSection from "../../../../../FlatComponents/Layout/FlatSection";
import { isNotNullOrUndefined } from "../../../../../Globals/GenericValidators";
import { useGetJobBreakdownForCommissionQuery } from "../../../../../generated/graphql";
import MaterialTable from "./tables/MaterialTable";
import LabourTable from "./tables/LabourTable";
import { Row, Col } from "react-bootstrap";
interface MaterialLaborCommisionBreakdownProps {
    jobConfigId:number
}

function MaterialLaborCommisionBreakdown({ jobConfigId: jobConfigurationId }: MaterialLaborCommisionBreakdownProps) {
   
    const { data: commissionBreakdownData } = useGetJobBreakdownForCommissionQuery({
        variables: {jobConfigurationId: jobConfigurationId },
        skip: jobConfigurationId < 1
    });

    return (
    <div className="flex-column">
            <div className="margin-vertical-sm">
                <FlatSection header={"Breakdowns"} collapsible >
                    {isNotNullOrUndefined(commissionBreakdownData?.jobBreakdownForCommission.areaBreakdownsForCommission) && (

                        <Row>
                            <Col>
                            <MaterialTable
                                areaBreakdownsForCommission={commissionBreakdownData!.jobBreakdownForCommission.areaBreakdownsForCommission}/>
                            </Col>
                            <Col>
                            
                                <LabourTable
                                    areaBreakdownsForCommission={commissionBreakdownData!.jobBreakdownForCommission.areaBreakdownsForCommission} />
                            </Col>
                    </Row>
                )}
            </FlatSection>
         </div>
     </div>
    );
}

export default MaterialLaborCommisionBreakdown;